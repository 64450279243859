import { EmbeddedLayout, LbLogoLoading, usePermissions } from "@lb/frontend";
import { contractsClientUrls, dashboardClientUrls, templatesClientUrls, USER_TYPE_VENDOR } from "@lb/utils";
import { Suspense, lazy } from "react";
import { Navigate, createBrowserRouter, useParams } from "react-router-dom";

const Matrix = lazy(() => import("./pages/Matrix"));
const MatrixCreateEdit = lazy(() => import("./pages/Matrix/CreateEdit"));
const Contracts = lazy(() => import("./pages/contracts"));
const CreateContract = lazy(() => import("./pages/contracts/RecordManagement"));
const EditContract = lazy(() => import("./pages/contracts/RecordManagement"));
const ContractSummary = lazy(() => import("./pages/contracts/Summary"));
const RejectContract = lazy(() => import("./pages/contracts/Summary/Reject"));
const Templates = lazy(() => import("./pages/Templates"));
const AppLayout = lazy(() => import("./components/AppLayout"));
const AccessDenied = lazy(() => import("./pages/AccessDenied"));

const MatrixProtected = () => {
  const { type } = useParams();
  const { permissions } = usePermissions();

  if (type === "reviewer" && permissions?.clm?.reviewerMatrix) {
    return <Matrix />;
  }

  if (type === "signatory" && permissions?.clm?.signatoryMatrix) {
    return <Matrix />;
  }

  return <Navigate to={dashboardClientUrls.base} replace />;
};

const EditMatrixProtected = () => {
  const { type } = useParams();
  const { permissions } = usePermissions();

  if (type === "reviewer" && permissions?.clm?.reviewerMatrix?.edit) {
    return <MatrixCreateEdit />;
  }

  if (type === "signatory" && permissions?.clm?.signatoryMatrix?.edit) {
    return <MatrixCreateEdit />;
  }

  return <Navigate to={dashboardClientUrls.base} replace />;
};

const CreateMatrixProtected = () => {
  const { type } = useParams();
  const { permissions } = usePermissions();

  if (type === "reviewer" && permissions?.clm?.reviewerMatrix?.create) {
    return <MatrixCreateEdit />;
  }

  if (type === "signatory" && permissions?.clm?.signatoryMatrix?.create) {
    return <MatrixCreateEdit />;
  }

  return <Navigate to={dashboardClientUrls.base} replace />;
};

const router = (permissions, client) =>
  createBrowserRouter([
    {
      path: "embedded",
      element: <EmbeddedLayout />,
      children: [
        {
          path: contractsClientUrls.slug,
          element: <Contracts isEmbedded={true} />,
        },
      ],
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<LbLogoLoading />}>
          <AppLayout />
        </Suspense>
      ),
      children: [
        {
          index: true,
          element: <Navigate to={contractsClientUrls.slug} replace />,
        },
        {
          path: contractsClientUrls.slug,
          children: [
            {
              index: true,
              element:
                permissions?.clm?.contracts || client?.userType === USER_TYPE_VENDOR ? (
                  <Contracts />
                ) : (
                  <Navigate to={dashboardClientUrls.base} replace />
                ),
            },
            {
              path: contractsClientUrls.create.slug,
              element: permissions?.clm?.contracts?.create ? <CreateContract /> : <Navigate to={dashboardClientUrls.base} replace />,
            },
            {
              path: contractsClientUrls.edit.slug,
              element: permissions?.clm?.contracts?.edit ? <EditContract /> : <Navigate to={dashboardClientUrls.base} replace />,
            },
            {
              path: contractsClientUrls.summary.slug,
              element: <ContractSummary />,
              children: [
                {
                  path: contractsClientUrls.summary.review.slug,
                  element: <RejectContract />,
                },
              ],
            },
          ],
        },
        {
          path: templatesClientUrls.slug,
          element: permissions?.clm?.templates ? <Templates /> : <Navigate to={dashboardClientUrls.base} replace />,
        },
        {
          path: "matrix",
          element: <Navigate replace to={"/matrix/signtory"} />,
        },
        {
          path: contractsClientUrls.matrix.slug,
          children: [
            {
              index: true,
              element: <MatrixProtected />,
            },
            {
              path: contractsClientUrls.matrix.create.slug,
              element: <CreateMatrixProtected />,
            },
            {
              path: contractsClientUrls.matrix.update.slug,
              element: <EditMatrixProtected />,
            },
          ],
        },
      ],
    },
  ]);

export default router;
